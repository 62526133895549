<template>
    <div id="background_bottom_nav"></div>
    <v-bottom-navigation
        id="app_bar"
        bg-color="app_bar"
        v-model="selected_menu"
        :mandatory="true"
        class="py-1"
        elevation="0"
    >
        <v-btn
            icon
            class="mr-2"
            to="/house"
            value="House"
            :variant="selected_menu == 'House' ? 'outlined' : 'text' "
            rounded="pill"
            :active="false"
        >
            <v-icon>{{selected_menu == "House" ? 'mdi-home' : 'mdi-home-outline'}}</v-icon>
        </v-btn>

        <v-btn
            icon
            class="mr-2"
            to="/recipes"
            value="Recipes"
            :variant="selected_menu == 'Recipes' ? 'outlined' : 'text' "
            rounded="pill"
            :active="false"
        >
            <v-icon>{{selected_menu == "Recipes" ? 'mdi-book-open-variant' : 'mdi-book-outline'}}</v-icon>
        </v-btn>

        <v-btn
            icon
            class="mr-2"
            to="/groceries"
            value="Groceries"
            :variant="selected_menu == 'Groceries' ? 'outlined' : 'text' "
            rounded="pill"
            :active="false"
        >
            <v-icon>{{selected_menu == "Groceries" ? 'mdi-format-list-bulleted-square' : 'mdi-format-list-checkbox'}}</v-icon>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
export default {
    name: 'BottomAppBar',
    data: () => ({
      selected_menu: "Recipes"
    }),
}
</script>

<style>
    #app_bar .v-bottom-navigation__content {
        justify-content: space-evenly !important;
    }
    #app_bar {
        bottom: max(0px, env(safe-area-inset-bottom)) !important;
    }
    #background_bottom_nav {
        width: 100vw;
        height: max(0px, env(safe-area-inset-bottom));
        background-color: rgb(var(--v-theme-app_bar));
        position: fixed;
        bottom: 0;
        z-index: 1000;
    }
</style>