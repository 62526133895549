<template>
  <v-layout>
    <BottomAppBar/>
    <v-main>
      <v-container
        fluid
        style="margin-top: max(0px, env(safe-area-inset-top)) !important;
        margin-bottom: max(0px, env(safe-area-inset-bottom)) !important;"
      >
        <AlertsGroup />
        <router-view/>
      </v-container>
    </v-main>
  </v-layout>
</template>

<script>
  import AlertsGroup from '@/components/Alerts/AlertsGroup.vue'
  import BottomAppBar from '@/components/Navigation/BottomAppBar.vue'
  import { useAlertStore } from '@/stores/alertStore';
  import { useRecipesStore } from '@/stores/recipesStore';
  import { useUserStore } from '@/stores/userStore';
  import { useAiStore } from '@/stores/aiStore';

  export default {
    name: 'App',
    components: {
      AlertsGroup,
      BottomAppBar,
    },
    data: () => ({
    }),
    mounted() {
        //init stores
        this.$store.alert = useAlertStore();
        this.$store.recipes = useRecipesStore();
        this.$store.user = useUserStore();
        this.$store.ai = useAiStore();
    }
  }
</script>

<style>
  .v-app-bar.v-toolbar {
    top: max(0px, env(safe-area-inset-top)) !important;
  }
  .v-btn {
    touch-action: manipulation;
  }
</style>