<template>
    <v-alert
        :type="alert.type"
        variant="elevated"
        density="comfortable"
        prominent
        closable
        @click:close="clear()"
    >
        {{ alert.message }}
    </v-alert>
</template>

<script>
export default {
    name: 'AlertView',
    props: {
        alert: Object, 
    },
    emits: [
        'onClear',
    ],
    data: () => ({
    }),
    methods: {
        clear() {
            this.$emit('onClear', this.alert)
        }
    },
    mounted() {
        if (this.alert?.type != 'error' && this.alert?.type != 'info') {
            setTimeout(
                () => { 
                    this.clear() 
                }, 
                3500
            );
        }
    },
}
</script>