import { defineStore } from 'pinia'

export const useAlertStore = defineStore({
    id: 'alert',
    state: () => ({
        alerts: []
    }),
    actions: {
        success(message) {
            this.alerts.push({ message, type: 'success' });
        },
        info(message) {
            this.alerts.push({ message, type: 'info' });
        },
        warning(message) {
            this.alerts.push({ message, type: 'warning' });
        },
        danger(message) {
            this.alerts.push({ message, type: 'error' });
        },
        error(error) {
            this.alerts.push({ message:`${error?.code ? error.code + ' : ' : ''} ${error.message}`, type: 'error' });
        },
        clear(alert) {
            this.alerts = this.alerts.filter(a => a != alert)
        },
        clearAll() {
            this.alerts = []
        },
    }
});