<template>
    <v-row
        v-if="alerts.length || pending_request"
        justify="center"
        style="width: 100%; position:fixed; z-index: 9999; top: 0; left: 0; margin: 0px;"
    >
        <v-col cols=12 class="pb-0" v-if="pending_request?.status">
            <AiRequest :pending_request="pending_request" @onClear="clearRequest"/>
        </v-col>

        <v-col cols=12 class="pb-0" v-for="alert in alerts" :key="alert">
            <AlertView v-if="alert?.type" :alert="alert" @onClear="clear"/>
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAlertStore } from '@/stores/alertStore';
import { useAiStore } from '@/stores/aiStore';
import AlertView from '@/components/Alerts/AlertView.vue'
import AiRequest from '@/components/Alerts/AiRequest.vue'

export default {
    name: 'AlertsGroup',
    components: {
        AlertView,
        AiRequest,
    },
    data: () => ({
    }),
    computed: {
        ...mapState(useAlertStore, ['alerts']),
        ...mapState(useAiStore, ['pending_request'])
    },
    methods: {
        ...mapActions(useAlertStore, ['clear', 'clearAll']),
        ...mapActions(useAiStore, ['clearRequest']),
    },
}
</script>