const d = new Date();

export const random_w_seed = (seed) => {
    var x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
}

export const sortWithSeed = (arr) => {
    var seed = d.getDate()+2*d.getMonth()+d.getDay();
    arr = arr.sort(() => 0.5 - random_w_seed(seed++))
    return arr
}

export const gen_id = () => {
    const length = 7;
    let result = '';
    result += Date.now();
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}