import { collection, setDoc, getDoc, doc, updateDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword, updateProfile, updateEmail } from "firebase/auth";
import { getCurrentUser } from 'vuefire'
import { db, auth } from '@/plugins/firebase.js'
import { fillHouseWithTemplate } from '@/services/housesData.js'
import { httpsCallable } from "firebase/functions";
import { functions } from "@/plugins/firebase.js";

export const myHouse = () => {
	return auth.currentUser.photoURL;
}

export const myHouseCollection = (path) => {
	let house_id = auth.currentUser.photoURL;
	return collection(db, `/houses/${house_id}/${path}`);
}

export const SharedCollection = (path) => {
	return collection(db, path);
}

export const createAccount = async (firstname, email, password, house_id) => {
	let payload = await createUserWithEmailAndPassword(auth, email, password)
	await updateProfile(auth.currentUser, { displayName: firstname })
	let uid = payload.user.uid
	
	let house = house_id;

	if(!house_id || house_id == 'new_house') {
		const createHouseAndAssignUser = httpsCallable(functions, 'authentication-createHouseAndAssignUser');
		let result = await createHouseAndAssignUser({uid, email, displayName: firstname})
		house = result.data;
		fillHouseWithTemplate(house);
	} else {
		createUser({displayName: firstname, email, uid}, house_id);
	}

	return updateProfile(auth.currentUser, {
		// we store house id here because no other choice, but I'm not really happy doing it
		photoURL: house
	})
}

export const updateMyAccount = async user => {
	let currentUser = await getCurrentUser()
	if (currentUser.email != user.email) {
		updateEmail(auth.currentUser, user.email).then(() => {
		}).catch((error) => {
		this.$store.alert.error(error)
		});
	}
	updateProfile(auth.currentUser, {displayName: user.displayName})
}

// in this doc because only used here, & doesn't work if placed in userData cause it calls myhousecollection
export const createUser = (user, house_id) => {
	const usersCollection = collection(db, `/houses/${house_id}/users`)
	return setDoc(doc(usersCollection, user.uid), {...user, accepted: false})
}

export const getUser = async (uid, house_id) => {
	const usersCollection = collection(db, `/houses/${house_id}/users`)
    const user = await getDoc(doc(usersCollection, uid))
    return user.exists ? user.data() : null
}

export const getMyUser = async () => {
    const user = await getDoc(doc(myHouseCollection('users'), auth.currentUser.uid))
    return user.exists ? user.data() : null
}

export const updateMyUser = (user) => {
	return updateDoc(doc(myHouseCollection('users'), auth.currentUser.uid), {...user})
}
