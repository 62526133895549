import { createVuetify } from 'vuetify'
import 'vuetify/styles'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

// const myCustomLightTheme = {
//     dark: false,
//     colors: {
//       background: '#ECEFF1',
//       surface: '#FFFFFF',
//       primary: '#08866B',
//       secondary: '#E0F2EE',
//       'on-secondary': "#0e4935",
//       error: '#c3263c',
//       info: '#32b4d8',
//       success: '#33b94c',
//       warning: '#e29937',
//       app_bar: '#37542B',

//       minus2_bg: '#e6f2e0',
//       minus1_bg: '#E0F2EE',
//       zero_bg: '#e8eefd',
//       plus1_bg: '#f6eed3',
//       plus2_bg: '#ffe9ed',
//     }
// }

const GusLightTheme = {
    dark: false,
    colors: {
      background: '#F2F2F2',
      surface: '#FFFFFF',
      primary: '#61944B',
      secondary: '#EDF2EA',
      'on-secondary': "#283D1F",
      error: '#DE1526',
      info: '#158ade',
      success: '#61944B',
      warning: '#F58C1B',
      // app_bar: '#37542B',
      app_bar: '#185713',
      app_main: '#185713',

      minus2_bg: '#e6f2e0',
      minus1_bg: '#E0F2EE',
      zero_bg: '#e8eefd',
      plus1_bg: '#f6eed3',
      plus2_bg: '#ffe9ed',
    }
}

export const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        }
    },
    theme: {
      defaultTheme: 'GusLightTheme',
      themes: {
        GusLightTheme,
      }
    },
})