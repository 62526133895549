import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
// vuetify
import 'vuetify/styles'
import { vuetify } from '@/plugins/vuetify.js'
// Vuefire
import { VueFire, VueFireAuth } from 'vuefire'
import { firebaseApp } from '@/plugins/firebase.js'
import "../public/fonts/icon-set-food/css/icon-set-food.css";
import './registerServiceWorker'

const app = createApp(App);
const pinia = createPinia()
app.config.globalProperties.$store = {};

app.use(pinia)
app.use(vuetify)
app.use(router);
app.use(VueFire, {
    firebaseApp,
    modules: [
    VueFireAuth(),
    ],
});

app.mount('#app');