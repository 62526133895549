import { ref, onUnmounted } from 'vue'
import { onSnapshot, doc, getDoc, updateDoc, addDoc } from "firebase/firestore";
import { myHouseCollection } from '@/services/userAuth'
import { sortWithSeed } from '@/services/utils'
import { thisMonthInspirationRecipes } from '@/assets/inspirationRecipes'

const requestsCollection = () => myHouseCollection('AI_Requests')

export const getTodaySeasonalRecipeInspiration = () => {
	const inspirationList = sortWithSeed([...thisMonthInspirationRecipes]).slice(0,4);
	return inspirationList
}

export const createRequest = request => {
    return addDoc(requestsCollection(), request)
}

export const getRequest = async id => {
	const request = await getDoc(doc(requestsCollection(), id))
	return request.exists ? request.data() : null
}

export const updateRequest = (id, request) => {
	return updateDoc(doc(requestsCollection(), id), request)
}

export const getAllRequests = async () => {
	return new Promise((resolve) => {
		const requests = ref([])
		const close = onSnapshot(requestsCollection(), snapshot => {
			requests.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
			resolve(requests)
		})
		onUnmounted(close)
	})
}

export const watchRequests = (callback) => {
	const close = onSnapshot(requestsCollection(), () => {
		callback()
	})
	onUnmounted(close)
	return
}