import { storage } from '@/plugins/firebase.js'
import { ref, uploadBytesResumable, deleteObject } from "firebase/storage";
import {  myHouse } from '@/services/userAuth'

const recipesImgRef = ref(storage, 'recipes_img');

export const updateRecipeImage = async (recipe_id, file) => {
  // const upload = await fetch('/api/imageResize', {
  //   method: 'POST',
  //   body: file,
  // })

  // console.log(upload)
  // const resizedFile = upload.body;
  // console.log(resizedFile)
  const house_id = myHouse();
  const recipeImgRef = ref(recipesImgRef, `${house_id}/${recipe_id}.jpg`);
  const uploadTask = uploadBytesResumable(recipeImgRef, file);

  uploadTask.on('state_changed', 
  (snapshot) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    // eslint-disable-next-line
    console.log('Upload is ' + progress + '% done');
  }, 
  (error) => {
    // eslint-disable-next-line
    console.log(error)
  }, 
  () => {
    console.log(storage);
    console.log(recipeImgRef);
    return
  }
  );
}

export const deleteRecipeImage = recipe_id => {
  const house_id = myHouse();
  const desertRef = ref(recipesImgRef, `${house_id}/${recipe_id}.jpg`);
  // todo : check if there is a file before delete to avoid 404
  deleteObject(desertRef).then(() => {
    // File deleted successfully
  }).catch((error) => {
    // eslint-disable-next-line
    console.log(error)
    // Uh-oh, an error occurred!
  });
}