<template>
    <v-alert
        v-if="pending_request?.status == 'in_progress'"
        type="info"
        variant="elevated"
        density="comfortable"
        prominent
        closable
    >
        <template v-slot:prepend>
            <v-progress-circular
                indeterminate
                color="white"
            ></v-progress-circular>
        </template> 
        Votre recette {{ pending_request.message }} est en cours de préparation
    </v-alert>
    <v-alert
        v-else
        type="success"
        variant="elevated"
        density="comfortable"
        prominent
        closable
        @click:close="clear()"
    >
        Votre recette {{ pending_request.message }} est prête 
        <v-btn @click="redirect()">ici</v-btn>
    </v-alert>
</template>

<script>
export default {
    name: 'AiRequest',
    props: {
        pending_request: Object, 
    },
    emits: [
        'onClear',
    ],
    data: () => ({
    }),
    methods: {
        clear() {
            this.$emit('onClear')
        },
        redirect() {
            this.$router.push({ path: '/recipe/import', query: { requestRecipe: this.pending_request.responseId } })
            this.clear()
        }
    },
}
</script>