import { ref, onUnmounted } from 'vue'
import { onSnapshot, collection, doc, getDoc, updateDoc, deleteDoc, addDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from '@/plugins/firebase.js'

const housesCollection = collection(db, "/houses");

export const createHouse = (house_id) => {
    setDoc(doc(housesCollection, house_id), {})
    return fillHouseWithTemplate(house_id)
}

export const fillHouseWithTemplate = async (house_id) => {
    const templateRecipesCollection = collection(db, "/houses/template/recipes");
    const templateListCollection = collection(db, "/houses/template/list");
    
    const newHouseRecipesCollection = collection(db, `/houses/${house_id}/recipes`);
    const newHouseListCollection = collection(db, `/houses/${house_id}/list`);

    const recipes_raw = await getDocs(templateRecipesCollection);
    const list_raw = await getDocs(templateListCollection);

    recipes_raw.forEach(d => {
        delete d.id;
        addDoc(newHouseRecipesCollection, d.data())
    })

    list_raw.forEach(d => {
        setDoc(doc(newHouseListCollection, d.id), d.data())
    })
}

export const getHouse = async id => {
    const house = await getDoc(doc(housesCollection, id))
    return house.exists ? house.data() : null
}

export const updateHouse = async (id, house) => {
        return updateDoc(doc(housesCollection, id), house)
}

export const deleteHouse = id => {
    return deleteDoc(doc(housesCollection, id))
}

export const useLoadHouses = () => {
    const houses = ref([])
    const close = onSnapshot(housesCollection, snapshot => {
        houses.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    })
    onUnmounted(close)
    return houses
}

export const getAllHouses = async () => {
    const querySnapshot = await getDocs(housesCollection);
    return querySnapshot
}