const inspirationRecipes = [
    "Roulé d'agneau aux herbes",
    "Tartare de saumon à la mangue",
    "Croquettes de crevettes croustillantes",
    "Pot-au-feu revisité",
    "Ceviche de poulpe aux agrumes",
    "Tajine de poulet aux abricots secs",
    "Poke bowl exotique",
    "Croustillant de chèvre au miel",
    "Carpaccio de betteraves colorées",
    "Cassolette de ris de veau aux morilles",
    "Raviolis aux cèpes et truffes",
    "Salade de quinoa aux fruits secs",
    "Verrine de gaspacho andalou",
    "Tournedos Rossini",
    "Wok de légumes sautés au sésame",
    "Médaillons de lotte aux agrumes",
    "Brochettes de magret de canard laqué",
    "Mille-feuille de légumes d'été",
    "Curry de poulet au lait de coco",
    "Duo de foie gras et saumon fumé",
    "Cannellonis aux épinards et ricotta",
    "Velouté de potiron au foie gras",
    "Blinis de patate douce au saumon",
    "Papillote de poisson aux légumes croquants",
    "Galette de sarrasin aux champignons",
    "Carpaccio de Saint-Jacques à l'huile de truffe",
    "Salade d'été aux framboises",
    "Moules farcies à la provençale",
    "Gâteau de pommes de terre au reblochon",
    "Pavé de saumon aux herbes fraîches",
    "Terrine de gibier aux pistaches",
    "Lasagnes aux légumes du soleil",
    "Chili con carne épicé",
    "Papillote de poulet aux petits légumes",
    "Boulettes de bœuf sauce barbecue",
    "Filet mignon de porc au miel et romarin",
    "Fricassée de volaille aux champignons",
    "Pain perdu aux fruits rouges",
    "Feuilleté de chèvre et poires",
    "Cassoulet maison",
    "Gnocchis à la crème de gorgonzola",
    "Crumble de légumes au parmesan",
    "Escalope de veau à la milanaise",
    "Hachis parmentier de canard confit",
    "Tagliatelles aux truffes",
    "Poêlée de gambas au chorizo",
    "Piperade basquaise",
    "Risotto aux cèpes",
    "Boulettes de pois chiches au cumin",
    "Poulet rôti à l'ail et au romarin",
    "Tatin d'endives au chèvre",
    "Velouté de topinambours",
    "Poêlée de légumes printaniers",
    "Cassolette d'escargots à la persillade",
    "Ris de veau aux girolles",
    "Tarte aux figues et au fromage de chèvre",
    "Duo de saumon et langoustine",
    "Gâteau de crabe",
    "Poulet yakitori",
    "Burrata crémeuse sur lit de tomates",
    "Confit de canard aux pommes",
    "Mignon de porc à la moutarde",
    "Tartelette aux asperges et parmesan",
    "Aubergines farcies à la provençale",
    "Crumble de courgettes au chèvre",
    "Caviar d'aubergines",
    "Rouleaux de printemps aux crevettes",
    "Bœuf sauté aux oignons et poivrons",
    "Poulet au curry vert",
    "Crevettes à la thaïlandaise",
    "Risotto aux fruits de mer",
    "Bœuf Stroganoff",
    "Fricassée de poulet à l'ancienne",
    "Tarte au citron meringuée revisitée",
    "Émincé de volaille aux champignons des bois",
    "Nems aux légumes",
    "Blinis au saumon gravlax",
    "Couscous royal",
    "Salade de pâtes aux légumes grillés",
    "Velouté de chou-fleur au bleu d'Auvergne",
    "Papillote de saumon aux légumes",
    "Crème brûlée au foie gras",
    "Salade de magret de canard séché et figues",
    "Moules à la crème d'ail",
    "Poêlée de champignons sauvages",
    "Tajine de légumes aux amandes",
    "Baba au rhum exotique",
    "Salade niçoise revisitée",
    "Cordon bleu maison",
    "Gambas flambées au pastis",
    "Risotto au safran",
    "Pavé de bœuf sauce au poivre",
    "Tarte Tatin d'oignons rouges",
    "Crêpes au jambon et champignons",
    "Poulet au cidre et aux pommes",
    "Tartelettes aux poireaux et lardons",
    "Poulet tandoori",
    "Rillettes de saumon",
    "Confiture d'oignons",
    "Pain d'épices au foie gras",
    "Poulet au citron confit",
    "Wok de bœuf aux légumes croquants",
    "Cake aux olives et au jambon",
    "Salade de poulpe aux agrumes",
    "Escalope de veau aux champignons",
    "Gratin de fruits de mer",
    "Bouchées à la reine",
    "Carpaccio d'ananas et de kiwi",
    "Cailles farcies aux raisins",
    "Champignons farcis aux escargots",
    "Tarte au camembert et aux pommes",
    "Fondue savoyarde",
    "Moules marinières à la crème",
    "Velouté de lentilles corail",
    "Cassolette de noix de Saint-Jacques",
    "Dorade en croûte de sel",
    "Soupe à l'oignon gratinée",
    "Salade de chèvre chaud aux noix",
    "Gratin dauphinois",
    "Pancakes au sirop d'érable",
    "Carpaccio de melon",
    "Confit de canard aux figues",
    "Gaspacho de concombre",
    "Bœuf bourguignon",
    "Tarte au chocolat et spéculoos",
    "Chili végétarien",
    "Croustillants de camembert",
    "Poulet aux olives et citrons confits",
    "Croque-monsieur au saumon fumé",
    "Mousse de saumon aux herbes fraîches",
    "Caviar d'aubergines au sésame",
    "Tatin d'abricots au romarin",
    "Wok de crevettes et légumes sautés",
    "Poulet rôti à la moutarde et au miel",
    "Tartare de thon au guacamole",
    "Tarte aux tomates cerises et chèvre frais",
    "Croquettes de pommes de terre aux truffes",
    "Gâteau au fromage de chèvre et aux poivrons",
    "Cannellonis de saumon fumé et ricotta",
    "Carpaccio de radis et concombre",
    "Magret de canard aux cerises",
    "Linguine aux fruits de mer",
    "Salade d'endives aux noix et roquefort",
    "Mille-feuille de courgettes",
    "Poke bowl au saumon et avocat",
    "Tartelette au citron vert et spéculoos",
    "Cassolette de fruits de mer à la crème",
    "Tajine d'agneau aux pruneaux",
    "Maki sushi au thon et avocat",
    "Fricassée de volaille au cidre",
    "Salade de quinoa et avocat",
    "Éclair au chocolat et caramel beurre salé",
    "Moules farcies à la marinière",
    "Gaspacho andalou",
    "Risotto aux asperges",
    "Crumble de fruits rouges",
    "Papillote de poisson au fenouil",
    "Poulet aux champignons des bois",
    "Poêlée de légumes d'été",
    "Cailles aux raisins",
    "Tartelette au saumon et poireaux",
    "Croustillant de boudin noir aux pommes",
    "Gratin de potiron au comté",
    "Poulet au curry rouge",
    "Mille-feuille de saumon fumé",
    "Pâté en croûte maison",
    "Gâteau de foie blond",
    "Carpaccio de mangue et avocat",
    "Velouté de courgettes au basilic",
    "Pavé de bœuf aux cèpes",
    "Moules au chorizo",
    "Soupe de poissons",
    "Poulet au miel et au gingembre",
    "Quiche au saumon et épinards",
    "Bœuf Wellington",
    "Salade de lentilles aux lardons",
    "Gâteau de polenta aux légumes",
    "Tarte aux poires et au roquefort",
    "Caviar de courgettes",
    "Piperade béarnaise",
    "Poêlée de saint-jacques au safran",
    "Raviolis au homard",
    "Blinis de pommes de terre au saumon fumé",
    "Salade d'orange à la marocaine",
    "Brochettes de bœuf mariné",
    "Gnocchis à la crème de truffe",
    "Tarte au chocolat et poires caramélisées",
    "Côte de bœuf au barbecue",
    "Verrine de ratatouille",
    "Linguine aux champignons sauvages",
    "Soupe de potiron aux châtaignes",
    "Poulet cajun",
    "Pavé de saumon au pesto",
    "Tartelette au citron et framboises",
    "Salade de fraises au basilic",
    "Mousse de canard aux figues",
    "Tartare de bœuf à l'italienne",
    "Verrine de chèvre frais et betterave",
    "Blinis aux herbes et saumon",
    "Risotto aux champignons",
    "Poulet au lait de coco et curry",
    "Cassolette de fruits de mer gratinée",
    "Crumble de poireaux au jambon",
    "Tatin d'échalotes au chèvre",
    "Poulet au vin jaune et aux morilles",
    "Tarte aux framboises et pistaches",
    "Moules au roquefort",
    "Côtelettes d'agneau aux herbes",
    "Tartare de thon aux agrumes",
    "Gratin de ravioles",
    "Poulet à l'estragon",
    "Salade de fenouil à l'orange",
    "Tajine de poulet aux dattes",
    "Carpaccio de courgettes et feta",
    "Bouillabaisse",
    "Quiche aux légumes du soleil",
    "Gratin de macaroni au fromage",
    "Salade de melon et jambon cru",
    "Rouleaux de printemps végétariens",
    "Wok de bœuf sauce teriyaki",
    "Gâteau aux noix et caramel",
    "Cassolette d'escargots au beurre d'ail",
    "Poulet aux épices indiennes",
    "Ratatouille provençale",
    "Croustillant de saumon aux épinards",
    "Salade de mangue et avocat",
    "Boulettes de volaille aux herbes",
    "Poêlée de champignons à l'ail",
    "Tartelette au citron et meringue",
    "Magret de canard aux poires",
    "Caviar d'aubergines à la menthe",
    "Moules marinières",
    "Gratin de légumes d'hiver",
    "Tarte au fromage de chèvre et figues",
    "Roulé de dinde aux marrons",
    "Poulet rôti aux herbes de Provence",
    "Tartare de saumon aux avocats",
    "Crumble de légumes du soleil",
    "Émincé de bœuf aux légumes",
    "Poulet aux olives",
    "Tarte Tatin",
]

const SeasonalInspirationRecipes = {
    1: [ "Cassolette de saint-jacques aux poireaux", "Risotto aux champignons sauvages", "Pot-au-feu revisité", "Tarte à l'oignon et au bleu", "Carpaccio de betteraves et chèvre frais", "Blanquette de veau aux morilles", "Salade d'endives aux noix et roquefort", "Gratin de chou-fleur au jambon", "Pavé de saumon sauce hollandaise", "Soupe de potiron et châtaignes", "Fondue savoyarde", "Coq au vin", "Croustillant de boudin noir aux pommes", "Salade de chou rouge aux agrumes", "Choucroute garnie", "Parmentier de confit de canard", "Moules marinières", "Crème brûlée au foie gras", "Tartiflette", "Cailles farcies aux raisins", "Tajine de poulet aux pruneaux", "Quiche aux poireaux et saumon fumé", "Roulé d'agneau aux herbes", "Poulet rôti aux épices", "Gratin dauphinois", "Velouté de topinambours", "Civet de sanglier", "Tarte au citron meringuée revisitée", "Cassoulet maison", "Magret de canard aux figues"],
    2: [ "Salade de chou kale aux agrumes", "Bœuf bourguignon", "Velouté de potiron au foie gras", "Poulet basquaise", "Salade d'endives au jambon et noix", "Blanquette de veau", "Poke bowl exotique", "Risotto aux cèpes", "Gratin de coquillettes aux truffes", "Tartare de saumon à la mangue", "Fricassée de poulet à la normande", "Tajine d'agneau aux légumes confits", "Moules à la crème d'ail", "Soupe à l'oignon gratinée", "Croquettes de crevettes croustillantes", "Gâteau de pommes de terre au reblochon", "Cassolette de noix de Saint-Jacques", "Poulet aux olives et citrons confits", "Gratin de courgettes et ricotta", "Chili con carne épicé", "Rouleaux de printemps aux crevettes", "Caviar d'aubergines", "Piperade basquaise", "Salade d'hiver aux agrumes", "Mille-feuille de légumes d'hiver", "Boulettes de bœuf sauce barbecue", "Fricassée de volaille aux champignons", "Pain perdu aux fruits rouges", "Crumble de légumes au parmesan", "Blinis de patate douce au saumon"],
    3: [ "Velouté de courgettes au basilic", "Pavé de saumon aux herbes fraîches", "Pot-au-feu de la mer", "Tarte aux poireaux et lardons", "Croustillant de chèvre au miel", "Salade d'asperges aux œufs mimosa", "Mijoté de bœuf aux carottes", "Tajine de poulet aux abricots secs", "Gratin de pommes de terre au morbier", "Roulé de printemps aux légumes", "Brochettes de magret de canard laqué", "Poke bowl aux crevettes", "Moules marinières au safran", "Soupe de poisson et sa rouille", "Cassolette d'escargots à la persillade", "Gnocchis à la crème de gorgonzola", "Bœuf Stroganoff", "Gratin d'épinards et saumon", "Quiche au chèvre et aux épinards", "Velouté de céleri-rave aux noisettes", "Poulet rôti à l'ail et au romarin", "Cannellonis aux épinards et ricotta", "Tarte aux poireaux et au saumon fumé", "Fricassée de volaille à l'ancienne", "Pain d'épices au foie gras", "Salade de chou-fleur et brocoli", "Bœuf Wellington", "Velouté de potimarron au lait de coco", "Crumble de légumes printaniers", "Blinis au saumon gravlax"],
    4: [ "Asperges blanches sauce mousseline", "Risotto aux pointes d'asperges", "Cassolette de fruits de mer gratinée", "Salade de printemps aux radis", "Gambas flambées au pastis", "Tartelette aux asperges et parmesan", "Tajine de légumes aux amandes", "Cake aux olives et au jambon", "Carpaccio de saumon aux agrumes", "Salade de fraises et feta", "Ratatouille niçoise", "Pavé de saumon sauce vierge", "Moules marinières à la crème", "Asperges vertes sautées à l'ail", "Gratin de pommes de terre et saumon", "Croustillant de poireaux au chèvre", "Tarte aux tomates et mozzarella", "Tagliatelles aux asperges vertes", "Carpaccio d'ananas et de kiwi", "Salade de chou rouge aux pommes", "Tajine de poulet aux citrons confits", "Cannellonis aux épinards et ricotta", "Velouté de carottes au gingembre", "Poulet au curry vert", "Tartelette au citron meringuée", "Pain perdu aux fraises", "Salade d'œufs mimosa", "Bœuf aux carottes et cumin", "Crumble de courgettes au chèvre", "Soupe de légumes printaniers", "Blinis au saumon et œufs de saumon"],
    5: [ "Salade de melon et jambon cru", "Roulé d'agneau aux herbes", "Pavé de saumon sauce à l'oseille", "Tarte aux épinards et ricotta", "Cassolette de ris de veau aux morilles", "Salade de fraises au basilic", "Gratin de légumes du soleil", "Tajine de poulet aux pruneaux", "Brochettes de bœuf mariné", "Crumble de fruits rouges", "Moules marinières à la provençale", "Asperges blanches sauce hollandaise", "Poke bowl au saumon et avocat", "Soupe de petits pois à la menthe", "Cassolette de fruits de mer gratinée", "Gratin d'aubergines et mozzarella", "Tarte aux tomates cerises et chèvre frais", "Tagliatelles aux asperges vertes", "Carpaccio de fraises", "Salade de chou-rave aux pommes", "Tajine de poulet aux olives", "Cannellonis aux épinards et ricotta", "Velouté d'asperges", "Poulet rôti à l'estragon", "Tartelette aux fraises et pistaches", "Pain perdu aux framboises", "Salade de quinoa aux légumes grillés", "Bœuf aux oignons nouveaux", "Crumble de courgettes au chèvre", "Soupe de carottes au cumin", "Blinis au saumon fumé et crème ciboulette"],
    6: [ "Salade de pastèque et feta", "Côte de bœuf au barbecue", "Pavé de saumon sauce au citron", "Tarte aux asperges et tomates", "Cassolette de moules au chorizo", "Salade de concombre à la grecque", "Gratin de courgettes et parmesan", "Tajine de poulet aux abricots", "Brochettes de crevettes et légumes", "Crumble aux cerises", "Moules marinières au vin blanc", "Asperges vertes sautées à l'ail", "Poke bowl exotique", "Soupe froide de melon et jambon cru", "Cassolette de fruits rouges", "Gratin de courgettes et mozzarella", "Tarte aux tomates et basilic", "Tagliatelles aux légumes du jardin", "Carpaccio de melon", "Salade de chou-fleur aux raisins", "Tajine de poulet aux citrons confits", "Cannellonis aux épinards et ricotta", "Velouté de courgettes au basilic", "Poulet au curry vert", "Tartelette au citron meringuée", "Pain perdu aux fraises", "Salade de concombre et radis", "Bœuf aux oignons nouveaux", "Crumble de courgettes au chèvre", "Soupe froide de concombre et menthe", "Blinis au saumon fumé et crème ciboulette"],
    7: [ "Salade de tomates et mozzarella", "Côtelettes d'agneau aux herbes", "Pavé de saumon sauce béarnaise", "Tarte aux légumes du soleil", "Cassolette de noix de Saint-Jacques", "Salade de fraises et pêches", "Gratin de légumes provençaux", "Tajine de poulet aux olives", "Brochettes de bœuf mariné", "Crumble aux fruits rouges", "Moules marinières à la crème", "Salade niçoise revisitée", "Poke bowl au saumon et avocat", "Soupe de melon et concombre", "Cassolette de fruits exotiques", "Gratin de courgettes et feta", "Tarte aux tomates cerises et chèvre frais", "Tagliatelles aux courgettes et tomates", "Carpaccio d'ananas et de kiwi", "Salade de chou-rave aux pommes", "Tajine de poulet aux citrons confits", "Cannellonis aux épinards et ricotta", "Velouté de carottes et cumin", "Poulet rôti à l'estragon", "Tartelette aux fraises et pistaches", "Pain perdu aux framboises", "Salade de quinoa aux légumes grillés", "Bœuf aux oignons nouveaux", "Crumble de courgettes au chèvre", "Gaspacho andalou", "Blinis au saumon fumé et crème ciboulette"],
    8: [ "Salade de pastèque et feta", "Côte de bœuf au barbecue", "Pavé de saumon sauce au citron", "Tarte aux asperges et tomates", "Cassolette de moules au chorizo", "Salade de concombre à la grecque", "Gratin de courgettes et parmesan", "Tajine de poulet aux abricots", "Brochettes de crevettes et légumes", "Crumble aux cerises", "Moules marinières au vin blanc", "Asperges vertes sautées à l'ail", "Poke bowl exotique", "Soupe froide de melon et jambon cru", "Cassolette de fruits rouges", "Gratin de courgettes et mozzarella", "Tarte aux tomates et basilic", "Tagliatelles aux légumes du jardin", "Carpaccio de melon", "Salade de chou-fleur aux raisins", "Tajine de poulet aux citrons confits", "Cannellonis aux épinards et ricotta", "Velouté de courgettes au basilic", "Poulet au curry vert", "Tartelette au citron meringuée", "Pain perdu aux fraises", "Salade de concombre et radis", "Bœuf aux oignons nouveaux", "Crumble de courgettes au chèvre", "Soupe froide de concombre et menthe", "Blinis au saumon fumé et crème ciboulette"],
    9: [ "Salade de figues et jambon cru", "Tournedos Rossini", "Tarte aux légumes du soleil", "Cassolette de noix de Saint-Jacques", "Salade de raisin et roquefort", "Gratin de légumes provençaux", "Tajine de poulet aux abricots secs", "Brochettes de poulet aux légumes", "Crumble aux fruits rouges", "Moules marinières au cidre", "Salade d'automne aux noix", "Poke bowl aux crevettes", "Soupe de potimarron", "Cassolette de fruits exotiques", "Gratin de courgettes et feta", "Tarte aux tomates et chèvre frais", "Tagliatelles aux courgettes et tomates", "Carpaccio de mangue et avocat", "Salade de céleri-rave et pommes", "Tajine de poulet aux pruneaux", "Cannellonis aux épinards et ricotta", "Velouté de carottes au gingembre", "Poulet rôti à l'ail et au romarin", "Tartelette aux figues et chèvre", "Pain perdu aux poires", "Salade de quinoa aux légumes grillés", "Bœuf aux oignons nouveaux", "Crumble de courgettes au chèvre", "Soupe de courge", "Blinis au saumon fumé et crème ciboulette"],
    10: [ "Velouté de potiron au foie gras", "Pavé de saumon sauce hollandaise", "Pot-au-feu revisité", "Tarte à l'oignon et au bleu", "Carpaccio de betteraves et chèvre frais", "Blanquette de veau aux morilles", "Salade d'endives aux noix et roquefort", "Gratin de chou-fleur au jambon", "Poulet basquaise", "Salade d'endives au jambon et noix", "Blanquette de veau", "Poke bowl exotique", "Risotto aux cèpes", "Gratin de coquillettes aux truffes", "Tartare de saumon à la mangue", "Fricassée de poulet à la normande", "Tajine d'agneau aux légumes confits", "Moules à la crème d'ail", "Soupe à l'oignon gratinée", "Croquettes de crevettes croustillantes", "Gâteau de pommes de terre au reblochon", "Cassolette de noix de Saint-Jacques", "Poulet aux olives et citrons confits", "Gratin de courgettes et ricotta", "Chili con carne épicé", "Rouleaux de printemps aux crevettes", "Caviar d'aubergines", "Piperade basquaise", "Salade d'hiver aux agrumes", "Mille-feuille de légumes d'hiver", "Boulettes de bœuf sauce barbecue"],
    11: [ "Salade de chou kale aux agrumes", "Bœuf bourguignon", "Velouté de potiron au foie gras", "Poulet basquaise", "Salade d'endives au jambon et noix", "Blanquette de veau", "Poke bowl exotique", "Risotto aux cèpes", "Gratin de coquillettes aux truffes", "Tartare de saumon à la mangue", "Fricassée de poulet à la normande", "Tajine d'agneau aux légumes confits", "Moules à la crème d'ail", "Soupe à l'oignon gratinée", "Croquettes de crevettes croustillantes", "Gâteau de pommes de terre au reblochon", "Cassolette de noix de Saint-Jacques", "Poulet aux olives et citrons confits", "Gratin de courgettes et ricotta", "Chili con carne épicé", "Rouleaux de printemps aux crevettes", "Caviar d'aubergines", "Piperade basquaise", "Salade d'hiver aux agrumes", "Mille-feuille de légumes d'hiver", "Boulettes de bœuf sauce barbecue", "Fricassée de volaille aux champignons", "Pain perdu aux fruits rouges", "Crumble de légumes au parmesan", "Blinis de patate douce au saumon"],
    12: [ "Salade de chou rouge aux agrumes", "Civet de sanglier", "Tarte au citron meringuée revisitée", "Cassoulet maison", "Magret de canard aux figues", "Cassolette de ris de veau aux morilles", "Roulé de dinde aux marrons", "Poulet rôti aux herbes de Provence", "Tartare de saumon aux avocats", "Crumble de légumes du soleil", "Émincé de bœuf aux légumes", "Poulet aux olives", "Tarte Tatin", "Salade d'automne aux agrumes", "Bœuf aux oignons et poivrons", "Poulet au curry vert", "Crevettes à la thaïlandaise", "Risotto aux fruits de mer", "Bœuf Stroganoff", "Fricassée de poulet à l'ancienne", "Tarte au citron meringuée revisitée", "Émincé de dinde aux champignons", "Poulet rôti aux épices", "Bouchées de saumon fumé", "Gratin de pommes de terre et patates douces", "Bœuf Wellington", "Rouleaux de printemps aux crevettes", "Pot-au-feu", "Blinis au saumon fumé et crème ciboulette"],
}

const d = new Date();
const thisMonth = d.getMonth() + 1
const thisMonthInspirationRecipes = SeasonalInspirationRecipes[thisMonth]

export { inspirationRecipes, SeasonalInspirationRecipes, thisMonthInspirationRecipes }
 