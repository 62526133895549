import { defineStore } from 'pinia'
import { watchRecipes, getAllRecipes } from '@/services/recipesData';
import { getAllIngredients, watchIngredients } from '@/services/ingredientsData';

export const useRecipesStore = defineStore({
    id: 'recipes',
    state: () => ({
        recipes: [],
        components: [],
        ingredients: [],
    }),
    actions: {
        getRecipe({id}) {
            return this.recipes.find(r => r.id == id);
        },
        getRecipeEnriched(id) {
            let recipe = this.recipes.find(r => r.id == id);

            const enrichedIngredients = recipe.ingredients.map(ingredient => {
                const ingredientInfo = this.ingredients.find(i => i.id == ingredient.id);
                return {...ingredient, ...ingredientInfo}
            })
            recipe.ingredients = enrichedIngredients;
            console.log(recipe)

            const enrichedComponents = recipe.components.map(component => {
                const componentInfo = this.components.find(c => c.id == component.id);

                const multiplicator = component.nb_persons / componentInfo.nb_persons 
                const ingredientsInfo = componentInfo.ingredients.map(ingredient => {
					const ingredientInfo = this.ingredients.find(i => i.id == ingredient.id);
					const qtity = ingredient.qtity * multiplicator
					ingredient.qtity = qtity
					return {...ingredient, ...ingredientInfo}
                })

                const stepsInfo = componentInfo.steps.map(step => {
                    const stepIngredientsInfo = step.ingredients.map(ingredient => {
                        const stepIngredientInfo = this.ingredients.find(i => i.id == ingredient.id);
                        return {...ingredient, ...stepIngredientInfo}
                    })
                    step.ingredients = stepIngredientsInfo
                    return step
                })

                component.ingredients = ingredientsInfo
                component.steps = stepsInfo
                component.name = componentInfo.name
                return component
            })

            recipe.components = enrichedComponents
        
            const enrichedSteps = recipe.steps.map(step => {
                if (step.type == "component") {
                    step.steps = recipe.components.find(el => el.id == step.id).steps
                    return step
                } else {
                    const ingredientsInfo = step.ingredients.map(ingredient => {
                        const ingredientInfo = this.ingredients.find(i => i.id == ingredient.id);
                        return {...ingredient, ...ingredientInfo}
                        })
    
                    step.ingredients = ingredientsInfo
                    return step
                }
            })
            recipe.steps = enrichedSteps
            return recipe;
        },
        async init() {
            if (!this.recipes.length) {
                await this.refresh()
                watchRecipes(this.refresh)
                watchIngredients(this.refresh)
            }
            return true
        },
        async refresh() {
            const all_recipes = await getAllRecipes();
            this.recipes = all_recipes.recipes;
            this.components = all_recipes.components;
            this.ingredients = await getAllIngredients();
            return true
        },
    },
});