import { defineStore } from 'pinia'
import { getAuth } from "firebase/auth";
import { getMyUser } from "@/services/userAuth";

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        uid: null,
        displayName: null,
        email: null,
        houseId: null,
        preferences: null,
    }),
    actions: {
        logout() {
            this.uid = null;
            this.displayName = null;
            this.email = null;
            this.houseId = null;
        },
        async setFromAuth() {
            const currentUser = this.getFromAuth()

            if (currentUser) {
                this.uid = currentUser.uid;
                this.displayName = currentUser.displayName;
                this.email = currentUser.email;
                this.houseId = currentUser.photoURL;
                const myUserData = await getMyUser()
                this.preferences = myUserData.preferences
            }
            return
        },
        getFromAuth() {
            const auth = getAuth();
            const user = auth?.currentUser;
            return user;
        },
    }
  });