import { defineStore } from 'pinia'
import { createRequest } from '@/services/aiRequestData';
import { httpsCallable } from "firebase/functions";
import { functions } from "@/plugins/firebase.js";
import { myHouse } from '@/services/userAuth'

export const useAiStore = defineStore({
    id: 'ai',
    state: () => ({
        pending_request: null,
    }),
    actions: {
        async makeAiRequest({requestRecipe, preferences}) {
            this.pending_request = {
                status: 'in_progress',
                requestRecipe,
            }

            const request = [requestRecipe, ...preferences].join(", ")
            console.log(request)

            const request_log = {
                method: "by_name",
                request,
                requestRecipe,
                preferences,
                timestamp: Date.now(),
                pending: true,
            }
            const doc = await createRequest(request_log)
            const requestId = doc.id
            this.pending_request.id = requestId

            const askForRecipeByName = httpsCallable(functions, 'openAI-askForRecipeByName');
            const response = await askForRecipeByName({requestRecipe: request, houseId: myHouse(), requestId})
            const responseId = response.data

            this.pending_request = {
                status: 'done',
                requestRecipe,
                requestId,
                responseId,
            }
            return 
        },
        async makeAiExtractRecipe({requestContent}) {
            this.pending_request = {
                status: 'in_progress',
                requestContent,
            }

            const request = requestContent
            console.log(request)

            const request_log = {
                method: "by_extraction",
                request,
                requestContent,
                timestamp: Date.now(),
                pending: true,
            }
            const doc = await createRequest(request_log)
            const requestId = doc.id
            this.pending_request.id = requestId

            const findRecipeInPageContent = httpsCallable(functions, 'openAI-findRecipeInPageContent');
            const response = await findRecipeInPageContent({requestContent: request, houseId: myHouse(), requestId})
            const responseId = response.data

            this.pending_request = {
                status: 'done',
                requestContent,
                requestId,
                responseId,
            }
            return 
        },
        clearRequest() {
            this.pending_request = null;
        },
    },
});