import { onUnmounted } from 'vue'
import { onSnapshot, doc, updateDoc, deleteDoc, addDoc, getDocs, query, where } from "firebase/firestore";
import { myHouseCollection, SharedCollection } from '@/services/userAuth'

const ingredientsCollection = () => myHouseCollection('ingredients')

export const normalizeIngredientName = name => {
  return name.toLowerCase().replace(/s(\s|$)/g, "").replace(/[\s_-]/g, "").replace(/\u0153/g, 'oe').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export const createIngredient = async ingredient => {
  ingredient.name = ingredient.name.toLowerCase()
  ingredient.normalized_name = normalizeIngredientName(ingredient.name);
  const q = query(ingredientsCollection(), where("normalized_name", "==", ingredient.normalized_name));
  const q_shared = query(SharedCollection('shared_ingredients'), where("normalized_name", "==", ingredient.normalized_name));
  const docs = await getDocs(q);
  const docs_shared = await getDocs(q_shared);
  if (docs.size || docs_shared.size) {throw new Error('Cet ingrédient existe déjà')}
  return await addDoc(ingredientsCollection(), ingredient)
}

export const createSharedIngredient = async ingredient => {
  ingredient.name = ingredient.name.toLowerCase()
  ingredient.normalized_name = normalizeIngredientName(ingredient.name);
  const q = query(ingredientsCollection(), where("normalized_name", "==", ingredient.normalized_name));
  const q_shared = query(SharedCollection('shared_ingredients'), where("normalized_name", "==", ingredient.normalized_name));
  const docs = await getDocs(q);
  const docs_shared = await getDocs(q_shared);
  if (docs.size || docs_shared.size) {throw new Error('Cet ingrédient existe déjà')}
  return await addDoc(SharedCollection('shared_ingredients'), ingredient)
}

export const updateIngredient = async (id, ingredient) => {
  ingredient.name = ingredient.name.toLowerCase()
  const temp = (' ' + ingredient.normalized_name).slice(1);
  ingredient.normalized_name = normalizeIngredientName(ingredient.name);
  const q = query(ingredientsCollection(), where("normalized_name", "==", ingredient.normalized_name));
  const q_shared = query(SharedCollection('shared_ingredients'), where("normalized_name", "==", ingredient.normalized_name));
  const docs = await getDocs(q);
  const docs_shared = await getDocs(q_shared);
  if ((docs.size || docs_shared.size) && temp != ingredient.normalized_name) {throw new Error('Vous ne pouvez pas renommer avec un nom déjà utilisé par un autre ingrédient')}
  if(ingredient.origin == 'house') {
    return updateDoc(doc(ingredientsCollection(), id), ingredient)
  } else {
    return updateDoc(doc(SharedCollection('shared_ingredients'), id), ingredient)
  }
  
}

export const deleteIngredient = id => {
  return deleteDoc(doc(ingredientsCollection(), id))
}

export const watchIngredients = (callback) => {
	const close = onSnapshot(ingredientsCollection(), () => {
		callback()
	})
	const close_shared = onSnapshot(SharedCollection('shared_ingredients'), () => {
		callback()
	})
	onUnmounted(close)
	onUnmounted(close_shared)
	return
}

export const getAllIngredients = async () => {
  const sharedIngredientsQuerySnapshot = await getDocs(SharedCollection('shared_ingredients'))
  const houseIngredientsQuerySnapshot = await getDocs(ingredientsCollection());
  const houseIngredients = houseIngredientsQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), origin: 'house' }))
  const sharedIngredients = sharedIngredientsQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), origin: 'shared' }))
  return [...sharedIngredients, ...houseIngredients]
}