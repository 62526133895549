import { ref, onUnmounted } from 'vue'
import { onSnapshot, doc, getDoc, updateDoc, deleteDoc, addDoc } from "firebase/firestore";
import { updateRecipeImage, deleteRecipeImage } from '@/services/recipeImageStorage'
import { myHouseCollection, myHouse, SharedCollection } from '@/services/userAuth'
import { sortWithSeed } from '@/services/utils'
import { httpsCallable } from 'firebase/functions';
import { functions } from "@/plugins/firebase.js";

const recipesCollection = () => myHouseCollection('recipes')

const formatRecipeIngredients = recipe => {
	var all_ingredients = [];
	let clonedSteps = JSON.parse(JSON.stringify(recipe.steps))
	console.log(clonedSteps)
	clonedSteps.filter(e => !!e.ingredients).forEach(step => all_ingredients.push(...step.ingredients))
	console.log(all_ingredients)
	const regrouped_ingredients = all_ingredients.reduce(
		(accumulator, currentValue) => {
			console.log(currentValue)
			currentValue.qtity = Number(currentValue.qtity)
			const i = accumulator.findIndex(el => el.id === currentValue.id && el.unit === currentValue.unit)
			if (i >= 0) {
				accumulator[i].qtity = accumulator[i].qtity + currentValue.qtity
			} else {
				accumulator.push(currentValue)
			}
			console.log(accumulator)
			return accumulator;
		},
		[]
	);
	console.log(regrouped_ingredients)
	recipe.ingredients = regrouped_ingredients;
	return recipe;
}

export const getSharedRecipe = async id => {
	const request = await getDoc(doc(SharedCollection('shared_recipes'), id))
	return request.exists ? request.data() : null
}

export const createRecipe = async recipe => {
	recipe = formatRecipeIngredients(recipe);
	const new_recipe = await addDoc(recipesCollection(), {...recipe, image: null});
	if (recipe.image) {
		await updateRecipeImage(new_recipe.id, recipe.image);
	} else {
		const askForImageRecipe = httpsCallable(functions, 'openAI-askForImageRecipe');
		await askForImageRecipe({houseId: myHouse(), recipeId: new_recipe.id, query: recipe.name})
	}
	return new_recipe;
}

export const getRecipe = async id => {
	const recipe = await getDoc(doc(recipesCollection(), id))
	return recipe.exists ? recipe.data() : null
}

export const updateRecipe = (id, recipe) => {
	recipe = formatRecipeIngredients(recipe);
	if (recipe.image) {
		updateRecipeImage(id, recipe.image);
		// to avoid race issue
		delete recipe.image_src;
	}
	return updateDoc(doc(recipesCollection(), id), {...recipe, image: null})
}

export const deleteRecipe = id => {
	deleteRecipeImage(id);
	return deleteDoc(doc(recipesCollection(), id))
}

export const getAllRecipes = async () => {
	return new Promise((resolve) => {
		const all_recipes = ref([])
		const close = onSnapshot(recipesCollection(), snapshot => {
			all_recipes.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
			all_recipes.value = sortWithSeed(all_recipes.value);
			const recipes = all_recipes.value.filter(r => r.type != "component")
			const components = all_recipes.value.filter(r => r.type == "component")
			resolve({recipes, components})
		})
		onUnmounted(close)
	})
}

export const watchRecipes = (callback) => {
	const close = onSnapshot(recipesCollection(), () => {
		callback()
	})
	onUnmounted(close)
	return
}